import axios from 'axios'
import { make } from 'vuex-pathify'

const STORY_SOURCE_TYPE = 'Story'
const MEDIUM_SOURCE_TYPE = 'Medium'

const getDefaultState = () => {
  return {
    exportFormats: [],
    fetchInitiated: false,
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  all({ exportFormats }) {
    return exportFormats
  },

  forStories({ exportFormats }) {
    return exportFormats.filter(({ source_type }) => source_type == STORY_SOURCE_TYPE);
  },

  forMedia({ exportFormats }) {
    return exportFormats.filter(({ source_type }) => source_type == MEDIUM_SOURCE_TYPE);
  },

  allAsObject({ exportFormats }) {
    let obj = {}
    exportFormats.forEach(exportFormat => {
      obj[exportFormat.name] = exportFormat.id
    })
    return obj
  },

  allAutoExports({ exportFormats }) {
    return exportFormats.filter(format => format.auto_export);
  },

  forIds: ({ exportFormats }) => (ids) => {
    return exportFormats.filter(exportFormat => ids.includes(exportFormat.id));
  },

  fieldsOnlyAsObject({exportFormats}) {
    let obj = {}
    exportFormats.filter(format => !format.master_template).forEach(format => {
      obj[format.name] = format.id
    })
    return obj;
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/export_formats')
      .then(response => {
        commit('SET_EXPORT_FORMATS', response.data.export_formats)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {
          root: true
        })
      })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
